[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
}

.row.over {
    border: .75px dashed #000;
}

.home-fragment {
    display: flex;
    margin-top: 30.75px;
    margin-left: 45px;
}

table {
    border-collapse: collapse;
}

.hover-last {
    opacity: .4;
    font-style: italic;
}

.question-table {
    top: 129px;
    left: 451px;
    width: 658.5px;
    height: 625.5px;
    border: solid #707070 .75px;
}

.question-table-header-row {
    height: 49.5px;
}

.question-table-header {
    border-bottom: solid #707070 .75px;;
    display: block;
}

.question-table-body td:nth-child(1):not(.checkbox) {
    padding-left: 41.25px;
    font-size: 22.5px;
    font-weight: bold;
    vertical-align: baseline;
}

.question-table-body td:nth-child(2).reorder-mode {
    padding-left: 41.25px;
    font-size: 22.5px;
    font-weight: bold;
    vertical-align: baseline;
    width: 26.25px;
}

.question-table-body td.id {
    font-size: 22.5px;
    font-weight: bold;
    vertical-align: baseline;
}

.question-table-body tr:first-child td {
    padding-top: 12.75px;
}

.question-table-body td:nth-last-child(2) {
    padding-right: 37.5px;
}

.question-table-header-row th:nth-last-child(2) {
    width: 284.25px;
}

.question-table-body td.checkbox {
    padding-left: 13.5px;
    display: block;
    width: 18.75px;
    height: 18.75px;

}

.checkbox {
    padding-right: 7.5px;
}

.prompt-caption {
    width: 232.5px;
    height: 53.25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2.25px 4.5px #00000029;
    border: .75px solid #0B0B0B;
    border-radius: 6px;
    opacity: 0.95;
    font-size: 13.5px;
    color: #0B0B0B;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-bottom: 26.25px;
    overflow: hidden;
}

.question-table-body {
    display: block;
    overflow-y: scroll;
    height: 578.25px;
}

.question-table-header-row th:nth-child(1):not(.checkbox) {
    width: 43.5px;
    padding-left: 37.5px;
}

.question-table-header-row th:nth-child(2).header-id {
    width: 43.5px;
}

.select-all {
    padding-left: 13.5px !important;
    display: block;
    width: 18.75px !important;
    height: 18.75px;
    padding-right: 7.5px !important;
    padding-top: 14px;
}

.checkmark {
    width: 18.75px;
    height: 17.75px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1.5px solid #F31717;
    display: block
}

.container > input:checked + .checkmark {
    border: 1.5px dashed red;
    background-color: black;
}

.container {
    cursor: pointer;
    font-size: 16.5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    padding-right: 7.5px;
}

.question-table-header-row th:nth-child(3) {
    width: 247.5px;
}

.question-table-header-row th {
    text-align: left;
    font-weight: bold;
    font-size: 22.5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}


.ddl-categories {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000033;
    border-radius: 7.5px;
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: 229.5px;
    height: 40.5px;
    padding-left: 9.75px;
}

.ddl-categories option {
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ddl-categories-prompt {
    top: 101px;
    left: 30px;
    width: 100%;
    height: 16.5px;
    text-align: left;
    font-size: 13.5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 0px;
    color: #0B0B0B;
    opacity: 1;
}

.categories-ddl-container {
    top: 66px;
    left: 30px;
    width: 229.5px;
    height: 40.5px;
    margin-bottom: 18px;
}

.left-column {
    margin-right: 54px
}

select::-ms-expand {
    display: none;
}

select {
    /*background-image: url('./icons/ddl_arrow.png') !important;*/
    /*-webkit-appearance: none;*/
    /*-moz-appearance: none;*/
    /*appearance: none;*/
    background-size: 10% !important;
    background-color: transparent !important;
    background-repeat: no-repeat !important;
    background-position: 198px 13.5px !important;
}

.img-camera {
    float: left;
    padding-right: 9px;
    padding-left: 9px;
    padding-top: 8.61px;
    width: 10.5%;
}

.img-play {
    float: left;
    padding-right: 8.49px;
    padding-left: 9.7px;
    padding-top: 14.25px;
    width: 9.5%;
}

.prompt {
    display: block;
    margin: 0px;
    width: 177px;
    padding-left: 49.5px;
    padding-top: 3px;
}

.caption {
    display: block;
    margin: 0px;
    padding-top: 16.5px;
    width: 177px;
    padding-left: 49.5px;
}

.caption .textarea {
    font-size: 13.5px;
    width: 177px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: none;
    overflow: hidden;
    padding-left: 0px;
    padding-top: 0px;
    resize: none;
}

.edit-focus {
    border: 3px solid #F31717;
}

.prompt .textarea {
    font-size: 13.5px;
    width: 177px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: none;
    overflow: hidden;
    padding-left: 0px;
    padding-top: 0px;
    resize: none;
}

text
select:focus {
    outline: 0;
}

.textarea:focus {
    outline: 0;
}

.line {
    width: .75px;
    height: 52.5px;
    background-color: #0B0B0B;
    opacity: 1;
    float: left;
}

.reorder-mode + td .prompt-caption {
    float: left;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(78, 12, 64, .56);
    z-index: 1;

}

.modal-main {
    position: fixed;
    background: white;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 657px;
    height: 415px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50.25px;
    opacity: 1;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal-header {
    text-align: center;
    font-weight: bold;
    font-size: 27px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 0px;
    color: #0B0B0B;
    opacity: 1;
    margin-bottom: 0px;
    margin-top: 28px;
}

.modal-subheader {
    text-align: center;
    font-size: 10.5;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 0px;
    color: #0B0B0B;
    opacity: 0.64;
    margin-top: 0px;
}

.add-question-header {
    text-align: left;
    font-size: 13.5;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 0px;
    color: #0B0B0B;
    opacity: 1;
    display: inline;

}

.add-question-subheader {
    display: inline;

}

.modal-container {
    text-align: center;
}

.modal-close {
    background: none;
    border: none;
    float: right;
    font-size: 13.5px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-right: 21px;
    margin-top: 16.5px;
    top: 0;
    right: 0;
    position: absolute;
}

.modal-body {
    text-align: center;
    display: inline-block
}

.modal-add-question.question {
    float: left;
    padding-right: 37.5px;
}

.modal-add-question {
    float: left;
    margin-top: 39;
}

.modal-submit {
    display: inline;
}

.modal-submit.add-another {
    padding-right: 37.5px;
}


.modal-submits {
    text-align: center;
}

.logout {
    background: none;
    border: none;
    float: right;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-right: 21px;
    margin-top: 16.5px;
    cursor: pointer;
}

.header {
    text-align: right;
}

.header-menu {
    display: inline-block;
    margin-top: 10.5px;
    margin-left: px;
}

.img-logo {
    position: absolute;
    bottom: 1%;
    right: 1%;

}

.modal-file-upload .file-upload {
    margin-bottom: 10px;
}

.modal-button {
    margin-top: 0px !important;
}


/* <><><><><><><<><><><><><><><>  */
.flex-container {
    height: 100%;
    padding: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.row {
    width: auto;
    border: 1px solid blue;
}

.flex-item {
    background-color: tomato;
    padding: 5px;
    width: 20px;
    height: 20px;
    margin: 10px;
    line-height: 20px;
    color: white;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
}

input[type="radio"] {
    border: 1px solid;
}