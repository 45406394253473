.edit-field{
    width: 229.5px;
    height: 37.5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4.5px #00000033;
    border-radius: 7.5px;
}

.login-prompt{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13.5;
    letter-spacing: 0px;
    color: #0B0B0B;
}

.login-form{
    text-align: left;
    display: inline-block;
}

.img-logo-login{
    left: 75%;
}

