body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edit-button{
  width: 232.5px;
  height: 57.5px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: lowercase;
  box-shadow: 0px 2.25px 4.5px #00000029;
  border: .75px solid #0B0B0B;
  border-radius: 6px;
  opacity: 0.95;
  margin-bottom: 25.5px;
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 17px;
  background-position: center 4.5px;
  background-size: 10%;
  text-shadow: -0.375px 0 black, 0 0.375px black, 0.375px 0 black, 0 -0.375px black;
  background-image:url('./pages/icons/edit_icon.png');
  cursor: pointer;
}

.edit-button.edit{
  margin-bottom: 23.25px;
  background-color: #f24424;
}

.edit-button.save{
  background-color: #259643;
  margin-top: 64.5px;
}

.edit-button.info{
  background-color: #256996;
  margin-top: 64.5px;
}

.edit-button.enter{
  margin-top: 42.75px;
}

